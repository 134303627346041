@import "../../Assets/Styles/colours";

$mobileSize: 900px;

nav.mobilenav {
    display: none;
    text-transform:  uppercase;
    font-weight: 700;
    width: 100%;
    position: absolute;
    z-index:999;
    background: $white;

    @media only screen and (max-width: $mobileSize) {
        &.open {
            display: flex;
            flex-direction: column;
        }
    }

    a {
        &:link, &:visited {
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: none;
        }

        &:hover {
            border-bottom: none;
            background: $grey;
        }
    }

    &.floating {
        padding-top: 60px;
    }

    &.dark {
        background: $black;

        a {
            &:link, &:visited {
                color: $white;
            }

            &:hover {
                background: $black;
            }
        }
    }
}

.navigation_fill {
    height: 65px;
    display: none;

    &.show {
        display: block;
    }
}

.navigation {

    &.scrolled {
        position: fixed;
        width: 100%;
        z-index: 500;
        height: 65px;
        background: transparentize($color: $white, $amount: 0.1);
        top: 0;
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        position:absolute;
        z-index: 1000;
        align-items: center;
    
        &.static {
            position: relative;
        } 
    }

    nav.desktopnav {
        display: flex;
        align-items: center;
        text-transform:  uppercase;
        font-weight: 700;

        @media only screen and (max-width: $mobileSize) {
            display: none;
        }
    }

    a {
        padding: 10px 0;
        margin: 0 15px;
        display: inline-block;

        &:link, &:visited {
            color: $white;
            text-decoration: none;
            border-bottom: solid 2px transparentize(#FFF, 1);

        }

        &:hover {
            color: $white;
            border-bottom: solid 2px $white;
        }
    }       

    .logo {
        padding-left: 0;
        width: 200px;
        margin: 0;

        &:link, &:visited {
            border-bottom: none;
        }

        &:hover {
            border-bottom: none;
        }

        img {
            width: 200px;
            display: block;
            filter: invert(100%);
            -webkit-filter: invert(100%);
        }
    }

    &.light {
        a {
            &:link, &:visited {
                color: $black;
                border-bottom: solid 2px transparentize($black, 1);
            }
    
            &:hover {
                color: $black;
                border-bottom: solid 2px $orange;
            }

            &:link.active_menu_link, 
            &:visited.active_menu_link {
                border-bottom: solid 2px $purple;
            }
        }

        .logo {
            &:link, &:visited {
                border-bottom: none;
            }
    
            &:hover {
                border-bottom: none;
            }

            img {
                filter: unset;
                -webkit-filter: unset;
            }
        }

        .mobile_btn span {
            background: $black;
        }
    }

    &.dark {
        background: $black;

        a {
            &:link, &:visited {
                color: $white;
                border-bottom: solid 2px transparentize($white, 1);
            }
    
            &:hover {
                color: $white;
                border-bottom: solid 2px $orange;
            }

            &:link.active_menu_link, 
            &:visited.active_menu_link {
                border-bottom: solid 2px $purple;
            }
        }

        .logo {
            &:link, &:visited {
                border-bottom: none;
            }
    
            &:hover {
                border-bottom: none;
            }
        }

        .mobile_btn span {
            background: $white;
        }
    }

    .mobile_btn {
        margin: 5px;
        width: 30px;
        height: 25px;
        position: relative;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -o-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        display: none;

        @media only screen and (max-width: $mobileSize) {
            display: inline-block;
        }

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: $white;
            border-radius: 0;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0);
            -moz-transform: rotate(0);
            -o-transform: rotate(0);
            -ms-transform: rotate(0);
            transform: rotate(0);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }

            &:nth-child(2) {
                top: 10px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }

            &:nth-child(3) {
                top: 20px;
                -webkit-transform-origin: left center;
                -moz-transform-origin: left center;
                -o-transform-origin: left center;
                transform-origin: left center;
            }
        }

        &.open {
            span {
                &:nth-child(1) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                    top: 0;
                    left: 0;
                }

                &:nth-child(2) {
                    width: 0;
                    opacity: 0;
                }

                &:nth-child(3) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    top: 21px;
                    left: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 800px) {
        height: auto;
    }

}