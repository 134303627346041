@import "../../Assets/Styles/colours";
@import "../../Assets/Styles/ui";

.contact {
    .form_container {
        min-width: 400px;
    }

    .contact_dark {
        background: $black;
        display: flex;
        flex-direction:row;
        color: $white;
        padding-top: 1em;
        padding-bottom: 1em;

        .instructions {
            width: 50%;
            font-size: 1.6em;
        }

        input[type="text"],
        input[type="email"],
        select,
        textarea {
            display: block;
            box-sizing: border-box;
            width: 100%;
            padding: 1rem 1.25rem;
            background: #363636;
            border: solid 2px #6A6A6A;
            color: $white;
            transition: all 0.5s;
            font-size: 1em;

            &:focus {
                outline:none;
                border: solid 2px $orange;
                color: $black;
                background: $white;
            }
        }

        label {
            display: block;
            font-size: 0.9em;
            font-weight: 600;
            margin-bottom: 5px;
        }
    }
}