import React, { useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import StaticLightNavLayout from "../../Layouts/StaticLightNavLayout"
import * as style from "./Contact.module.scss"
import ContactForm from "./ContactForm"

const Contact = () => {

    return (
        <StaticLightNavLayout>
            <Helmet>
                <title>Contact Taurid Media</title>
            </Helmet>

            <div className={style.contact}>
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col12">
                                <h2>Contact us</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${style.contact_dark}`}>
                    <div className="container">
                        <div className="row">
                            <div className={`col6 ${style.instructions}`}>
                                Fill out the form and we'll get back to you as soon as we can.
                            </div>
                            <div className={`col6 ${style.form_container}`}>
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StaticLightNavLayout>
    )
}

export default Contact;