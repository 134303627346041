import React, { useState } from "react"
import StaticLightNavLayout from "../Layouts/StaticLightNavLayout"

const Counter = () => {
    const [count, setCount] = useState(0)

    return (
        <StaticLightNavLayout>
            <div>
                <div>Current count: {count}</div>
                <button onClick={() => { setCount(count + 1) }}>Increment</button>
                <button onClick={() => { setCount(count + -1) }}>Decrement</button>
            </div>
        </StaticLightNavLayout>
    )
}

export default Counter;