.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 100px;

    .text {
        display: block;
    }
}