import React, { useRef, useState } from "react"
import emailjs from '@emailjs/browser'

import * as style from "./Contact.module.scss"

const ContactForm = () => {
    const form = useRef();
    const [complete, setComplete] = useState(false)
    const [formData, setFormData] = useState(
        {
            name: "",
            email: "",
            message: ""
        }
    )

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_un2lknt', 'template_lc0z9yo', form.current, '0i5TeY94AyZTeSxIq')
            .then((result) => {
                console.log(result.text);
                setComplete(true)
            }, (error) => {
                console.log(error.text);
            });
    };

    if (complete === false) {
        return (
            <form ref={form} onSubmit={sendEmail}>
                <p>
                    <label htmlFor="name">Your Name</label>
                    <input type="text" name="name" id="name" required value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                </p>
                <p>
                    <label htmlFor="email">Your Email</label>
                    <input type="email" name="email" id="email" required value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                </p>
                <p>
                    <label htmlFor="message">Message</label>
                    <textarea name="message" id="message" required />
                </p>
                <p>
                    <input type="submit" className={style.btn} value="Send" />
                </p>
            </form>
        );
    } else {
        return (
            <div>
                <h4>Thank you for contacting us, {formData.name}.</h4>
                <p>We'll be in touch soon at {formData.email} to discuss your requirements further.</p>
            </div>
        )
    }

};

export default ContactForm