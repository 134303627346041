import React from "react"
import * as style from "./CallToAction.module.scss"

const CallToAction = (props) => {

    return (
        <div className={style.call_to_action}>

            <div className="container">
                <div className="row">
                    <div className="col-5">
                        <h3>Have a project you want to work with us on?</h3>
                    </div>
                    <div className="col-7">
                        <p>Taurid Media can help you achieve lift-off on a variety of
                            digital and installation campaigns designed to amaze and delight.
                            To find out how, please get in touch.</p>

                        <a href="#" className={style.btn}>Get in touch</a>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default CallToAction