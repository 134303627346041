import React, { useEffect } from "react"
import { Link } from "react-router-dom";
import * as style from "./Home.module.scss"
import VideoPlayer from "../YouTubeVideoPlayer/YouTubeVideoPlayer"
import CallToAction from "../CallToAction/CallToAction"
import StaticLightNavLayout from "../../Layouts/StaticLightNavLayout"

const Home = (props) => {
    const meteorIcon = new URL("../../Assets/Images/meteor.png", import.meta.url)
    const featuredCsImage = new URL("../../Assets/Images/koho.jpg", import.meta.url)


    return (
        <StaticLightNavLayout>
            <div className={style.home}>
                <VideoPlayer VideoCode="F3J0h4i4MrE" AutoPlay={true} Controls={false} Interactive={false} Loop={true} />

                <div className={style.launch}>
                    <div className={`container ${style.innercontent}`}>
                        <div>
                            <h2>
                                Launch your business towards success with digital
                                content that will create a <span className={style['meteoric-text']}>meteoric</span> impact.
                            </h2>
                            <Link to="/counter" className={style.btn}>Our services</Link>
                        </div>
                        <img src={meteorIcon} alt="Meteor" />
                    </div>
                </div>

                <VideoPlayer VideoCode="8P7DJPMncVM" AutoPlay={false} Controls={false} />

                <div className={`${style['what-we-do']}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <h3>
                                    Launching and re-orbiting brands by
                                    marrying design, development and marketing.
                                </h3>
                            </div>
                            <div className="col-6">
                                <h4>Creative</h4>
                                <ul>
                                    <li>Brand identity design</li>
                                    <li>User experience design (UX)</li>
                                    <li>User interface design (UI)</li>
                                    <li>Wireframes and prototyping</li>
                                    <li>Campaign planning</li>
                                    <li>Content design and creation</li>
                                    <li>Photography</li>
                                    <li>Videography & post-production</li>
                                </ul>

                                <h4>Development</h4>
                                <ul>
                                    <li>Progressive web apps (PWA)</li>
                                    <li>Bespoke website development</li>
                                    <li>Business management systems</li>
                                    <li>Email marketing</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${style.orgs}`}>
                    <div className="container">
                        <div className="row">
                            <div className={`col-12 ${style.items}`}>
                                <img src="https://placeimg.com/640/480/tech" alt="" />
                                <img src="https://placeimg.com/640/480/tech" alt="" />
                                <img src="https://placeimg.com/640/480/tech" alt="" />
                                <img src="https://placeimg.com/640/480/tech" alt="" />
                                <img src="https://placeimg.com/640/480/tech" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`${style['mission-statement']}`}>
                    <p>
                        We work closely with businesses, brands, and startups
                        by delivering them with standout content and engaging
                        web creation, ensuring they shine in an ever-changing
                        world.
                    </p>

                    <Link to="/our-work" className={style.btn}>Our work</Link>
                </div>

                <CallToAction />

                {/* <div className={`${ style['lincoln']}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">

                            <figure class="quote">
                                <blockquote>
                                    The best way to predict the future is to create it.
                                </blockquote>
                                <figcaption>
                                    &mdash; Jeremy Keith, <cite>Mental models</cite>  </figcaption>
                            </figure>

                        </div>
                    </div>
                </div>
            </div> */}
            </div>
        </StaticLightNavLayout>
    )
}

export default Home