import React, { Suspense, lazy, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import apClient from "./GraphQl/ApolloClient"
import Home from "./components/Home/Home"
import Contact from "./components/Contact/Contact"
import Counter from "./components/Counter"
import * as style from "./App.module.scss"
const OurWork = lazy(() => import("./components/OurWork/OurWork"));
const CaseStudy = lazy(() => import("./components/CaseStudy/CaseStudy"));
const AboutUs = lazy(() => import("./components/AboutUs/AboutUs"))
const OurServices = lazy(() => import("./components/OurServices/OurServices"))
const Service = lazy(() => import("./components/OurServices/Service"))
const CookiePolicy = lazy(() => import("./components/CookiePolicy/CookiePolicy"))
import StaticLightNavLayout from "./Layouts/StaticLightNavLayout"
import Footer from "./components/Footer/Footer"
import Loading from "./components/Loading/Loading"

export function App() {
    return (
        <React.StrictMode>
            <HelmetProvider>
                <ApolloProvider client={apClient}>
                    <div className={style.app}>
                        <Helmet>
                            <title>Taurid Media</title>
                            <link rel="canonical" href="https://www.tauridmedia.com/" />
                        </Helmet>
                        <BrowserRouter>

                            <div className={style.tm_content}>
                                <Suspense fallback={<StaticLightNavLayout><Loading text="Loading page..." /></StaticLightNavLayout>}>
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route path="/services" element={<OurServices />} />
                                        <Route path="/services/:slug" element={<Service />} />
                                        <Route path="/our-work" element={<OurWork />} />
                                        <Route path="/our-work/case-study/:slug/" element={<CaseStudy />} />
                                        <Route path="/cookie-policy" element={<CookiePolicy />} />
                                        <Route path="/contact" element={<Contact />} />
                                        <Route path="/about-us" element={<AboutUs />} />
                                    </Routes>
                                </Suspense>
                            </div>

                            <Footer />

                        </BrowserRouter>
                    </div>

                </ApolloProvider>
            </HelmetProvider>
        </React.StrictMode>
    )
}