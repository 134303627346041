import React, { useEffect } from "react"
import { Link } from "react-router-dom";
import * as style from "./Footer.module.scss"

const Footer = () => {
    const instagramIcon = new URL("../../Assets/Images/instagram_icon.png", import.meta.url)
    const vimeoIcon = new URL("../../Assets/Images/vimeo_icon.png", import.meta.url)

    return (
        <div className={`${style.footer}`}>
            <div className={`${style.content}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-6">

                            <div className={`${style.social}`}>
                                <a href="https://www.instagram.com/taurid_media/" target="_BLANK">
                                    <img src={instagramIcon} alt="Instagram" />
                                </a>

                                <a href="https://vimeo.com/user130104856" target="_BLANK">
                                    <img src={vimeoIcon} alt="Vimeo" />
                                </a>
                            </div>

                        </div>
                        <div className="col-6">
                            <div className={style.copyright}>
                                © 2019-2022 Taurid Media Ltd
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-7">

                            <nav className={`${style.links}`}>
                                <Link to="/counter">Services</Link>
                                <Link to="/our-work">Our work</Link>
                                <Link to="/counter">About us</Link>
                                <Link to="/counter">Blog</Link>
                                <Link to="/counter">Contact</Link>
                            </nav>

                        </div>
                        <div className="col-5">
                            <nav className={`${style.policylinks}`}>
                                <Link to="/cookie-policy">Cookie policy</Link>
                                <Link to="/counter">Privacy policy</Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer