import React, { useState, useEffect } from "react"
import { Link, NavLink } from "react-router-dom";
import { debounce } from "lodash"
import * as style from "./Navigation.module.scss"

const Navigation = ({ staticPosition, light }) => {
    const [navOpen, setNavOpen] = useState(false)
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        const debouncedScrollEvent = debounce(() => {
            console.log("Scrolled!")
            setScroll(window.scrollY > 0);
        }, 50)

        const scrollEvent = () => {
            console.log("Scrolled!")
            setScroll(window.scrollY > 0);
        }

        window.addEventListener("scroll", scrollEvent, { passive: true })

        return () => window.removeEventListener("scroll", scrollEvent)
    }, []);

    const toggleNavigation = () => {
        if (navOpen) {
            setNavOpen(false)
        } else {
            setNavOpen(true)
        }
    }

    const logo = new URL("../../Assets/Images/TauridLogo.png", import.meta.url)

    return (
        <>
            <div className={`${style.navigation_fill} ${scroll && staticPosition ? style.show : ''}`}></div>
            <div className={`${style.navigation} ${light ? style.light : style.dark} ${scroll ? style.scrolled : ''}`}>
                <div className={`container`}>
                    <div className="row">
                        <div className={`col-12 ${style.wrapper} ${staticPosition ? style.static : ''}`}>

                            <Link to="/" className={style.logo}>
                                <img src={logo} alt="Taurid Media" />
                            </Link>

                            <div
                                className={`${style.mobile_btn} ${navOpen ? style.open : ''}`}
                                onClick={() => toggleNavigation()}
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                            <nav className={`${style.desktopnav}`}>
                                <NavLink to="/services" className={({ isActive }) => (isActive ? style.active_menu_link : '')}>Services</NavLink>
                                <NavLink to="/our-work" className={({ isActive }) => (isActive ? style.active_menu_link : '')}>Our work</NavLink>
                                <NavLink to="/about-us" className={({ isActive }) => (isActive ? style.active_menu_link : '')}>About us</NavLink>
                                {/* <NavLink to="/counter">Blog</NavLink> */}
                                <NavLink to="/contact" className={({ isActive }) => (isActive ? style.active_menu_link : '')}>Contact</NavLink>
                            </nav>

                        </div>
                    </div>
                </div>

                <nav className={`${style.mobilenav} ${navOpen ? style.open : ''} ${staticPosition ? '' : style.floating} ${!light ? style.dark : ''}`}>
                    <Link to="/services">Services</Link>
                    <Link to="/our-work">Our work</Link>
                    <Link to="/about-us">About us</Link>
                    {/* <Link to="/counter">Blog</Link> */}
                    <Link to="/contact">Contact</Link>
                </nav>
            </div>
        </>
    )
}

export default Navigation