@import "../../Assets/Styles/colours";
@import "../../Assets/Styles/ui";

.home {
    width: auto;

    .launch {
        background: url("../../Assets/Images/SpaceBg1.jpg");
        background-size: cover;
        height: 600px;
        color: #fff;

        @media only screen and (max-width: 1000px) {
            height: 800px;
        }

        @media only screen and (max-width: 800px) {
            height: unset;
        }

        .innercontent {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;

            @media only screen and (max-width: 800px) {
                flex-direction: column;
            }

            div {
                margin: 0;
                padding: 0;
                width: 50%;

                @media only screen and (max-width: 800px) {
                    width: 90%;
                }
            }

            .meteoric-text {
                color: $orange;
            }
    
            img {
                width: 30%;

                @media only screen and (max-width: 800px) {
                    display: none;
                }
            }

        }
    }

    .featured-case-study {
        display: flex;
        margin: 30px 0;

        img {
            width: 40%;
            height: auto;
            object-fit: cover;
        }

        .content {
            width: 60%;
            padding: 10%;
            box-sizing: border-box;
        }

        @media only screen and (max-width: 600px) {
            flex-direction: column;

            img {
                width: 100%;
            }

            .content {
                width: 100%;
            }
        }
    }

    .orgs {
        background-color: $grey;
        padding: 40px 0;

        .items {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            @media only screen and (max-width: 1000px) {
                flex-direction: column;
                justify-content: space-between;
            }
        }

        img {
            height: 120px;
            width: auto;

            @media only screen and (max-width: 1000px) {
                height: unset;
                width: 65%;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .mission-statement {
        background: $purple;
        color: $white;
        padding: 20vh;
        text-align: center;

        @media only screen and (max-width: 800px) {
            padding: 3em;
        }

        p {
            max-width: 600px;
            margin: auto;
            font-size: 1.9em;
            font-weight: 700;
            text-align: left;
            clear: both;
            display: block;
        }

        .btn {
            display: inline-block;
            margin-top: 30px;
        }
    }

    .what-we-do {
        background: $black;
        color: $white;
        padding: 3em 0;

        @media only screen and (max-width: 800px) {
            padding: 1em;
        }

        h4 {
            margin-bottom: 0.5em;
        }

        ul {
            list-style: none;
            font-size: 1.2em;
            margin: 0;
            padding: 0;
            color: darken($grey, 10%);

            li {
                padding: 0.2em 0;
            }
        }
    }
}
