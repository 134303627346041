import React, { FC, useEffect, useState } from "react"
import * as style from "./YouTubeVideoPlayer.module.scss"

type props = {
    VideoCode: string;
    Controls: boolean;
    AutoPlay: boolean;
    Interactive: boolean;
    Loop: boolean;
}

const YouTubeVideoPlayer: FC<props> = (props) => {

    const show_controls = props.Controls ? 1 : 0;
    const auto_play = props.AutoPlay ? 1 : 0;
    const loop = props.Loop ? 1 : 0;

    const [isValidVideo, setValidVideo] = useState<boolean>(true)
    const [videoCode, setVideoCode] = useState<string>("");

    useEffect(() => {
        extractVideoCode(props.VideoCode);

        console.log("Starting video..", videoCode)
    })

    const extractVideoCode = (vidCode:string) => {
        let rgx = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/g

        if(!rgx.test(vidCode)){
            setVideoCode(vidCode);
            return
        }
        
        rgx.lastIndex = 0; //After test above, have to reset index, as regexp is stateful!
        let result = rgx.exec(vidCode)
        if(result == null) {
            setValidVideo(false)
        }
        else {
            setVideoCode(result[6])
        }
    }

    if(isValidVideo)
    {
        return (
            <div className={style.videoplayer}>
                <div className={props.Interactive ? "" : style.interactive_block}></div>
                <iframe
                    className={style.youtubeflexivid}
                    allowFullScreen
                    frameBorder="0"
                    src={`https://www.youtube-nocookie.com/embed/${videoCode}?hd=1&playlist=${videoCode}&rel=0&modestbranding=1&autohide=1&showinfo=0&wmode=opaque&controls=${show_controls}&autoplay=${auto_play}&mute=${auto_play}&loop=${loop}`}
                />
            </div>
        )
    } else {
        return (
            <div>Invalid video code! {props.VideoCode}</div>
        )
    }
    
}

YouTubeVideoPlayer.defaultProps = {
    Controls: false, 
    AutoPlay: false,
    Interactive: true,
    Loop: false
}

export default YouTubeVideoPlayer