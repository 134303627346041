.videoplayer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .interactive_block {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
    
    &:after {
        display: block;
        content: "";
        padding-top: 56.25%;
    }

    .youtubeflexivid {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}