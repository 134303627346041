@import "../../Assets/Styles/colours";
@import "../../Assets/Styles/ui";

.call_to_action {
    background: #FFF;
    padding: 5em 0;

    h3 {
        margin: 0;
    }

    p {
        margin: 6px 0;
        font-size: 1.2em;
    }

    .btn {
        margin-top: 30px;
        display: inline-block;
    }
}