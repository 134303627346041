@import "../../Assets/Styles/colours";
@import "../../Assets/Styles/ui";

.footer {
    min-height: 220px;
    background: $black;
    color: $white;
    display: flex;
    flex-direction:column;
    justify-content: end;

    .content {
        min-height: 160px;

        @media only screen and (max-width: 900px) {
            margin: 20px 0;
        }
    }

    .social {
        display: flex;
        flex-direction: row;
        justify-content:start;
        align-items: flex-start;

        > a {
            margin-right: 10px;
            display: inline-block;
        }

        img {
            width: 35px;
            height: 35px;
        }

        @media only screen and (max-width: 900px) {
            padding: 30px 0;
        }
    }

    nav.policylinks {
        justify-content: flex-end;

        @media only screen and (min-width: 720px) and (max-width: 900px) {
            align-items: end;
        }
    }

    nav.links, nav.policylinks {
        padding: 0;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        a {
            color: $white;
            text-transform: uppercase;
            font-weight: 700;
            margin-right: 20px;

            &:last-child {
                margin-right: unset;
            }
        }

        @media only screen and (max-width: 900px) {
            margin-top: 0;
            margin-right: 0;

            flex-direction: column;

            a {
                width:fit-content;
                margin-right: unset;
            }

            a:not(:first-child) {
                margin-top: 20px;
            }

            a:last-child {
                margin-bottom: 20px;
            }
        }
    }

    .copyright {
        height: 40px;
        display: flex;
        justify-content: right;
        align-items: center;

        @media only screen and (min-width: 720px) and (max-width: 900px) {
            padding: 30px 0;
        }

        @media only screen and (max-width:719px) {
            justify-content: left;
        }
    }
}