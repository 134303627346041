@import url("./Assets/Styles/SimpleGrid.scss");
@import "./Assets/Styles/colours";
@import url("npm:react-image-gallery/styles/scss/image-gallery.scss");

body {
    font-size: 16px;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
}

.app {
    font-size: 1em;
}

h1 {
    font-size: 2em;
    color: $purple;
}

h1 + h2 {
    margin-top: 0;
}

h2 {
    font-size: 3em;
}

h3 {
    font-size: 2em;
}

h4 {
    font-size: 1.4em;
}

h5 {
    font-size: 1.2em;
}

//Creates the sticky footer
.tm_content {
    min-height: calc(100vh - 220px);
}

@media only screen and (max-width: 800px) {
    h2 {
        font-size: 2.2em;
    }
    
    h3 {
        font-size: 1.8em;
    }
    
    h4 {
        font-size: 1.5em;
    }
    
    h5 {
        font-size: 1.2em;
    }
}